

const signs = [
    {
        path: '/sign1',
        name: 'SignTemp-1',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../../views/sign/SignTemp-1/Index')
    },
    {
        path: '/sign1-details',
        name: 'SignTemp-1-details',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../../views/sign/SignTemp-1/Details')
    }
]

export default signs
