const theme = {
    state: {
        themeClass: ''
    },
    mutations: {
        CHANGE_THEME(state, theme) {
            state.themeClass = theme
        }
    },
    actions: {
    },
}
export default theme
