

const draws = [
    {
        path: '/draw1',
        name: 'DrawTemp-1',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../../views/draw/DrawTemp-1/Index')
    },
    {
        path: '/draw1-list',
        name: 'DrawTemp-1-List',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../../views/draw/DrawTemp-1/PrizeList')
    }
]

export default draws
