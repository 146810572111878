import axios from "axios"
// import Cookies from "js-cookie"
// import{removeToken} from '@/utils/auth'
import {Message} from "element-ui";
import store from "../../store/index";
import router from '../../router/index'

const instance = axios.create({
    // 设置超时时间，所有请求都会等待 4 秒
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
    }
})

export const config = {
    // gx 本地
    // baseURL: 'http://316yc82609.51vip.biz'
    // baseURL: 'http://10.16.39.9:18013'   // 高翔本地test
    // baseURL: 'http://172.24.7.116:18013'   // 高翔本地开发版本
    // 测试 and 正式
    // baseURL: 'http://116.198.41.236/activityApp',
    baseURL: '/activityApp',
    // 合肥


}

export function get(url, params) {
    let authType = store.state.user.authType
    let headers = {
        ...config.headers,
        token: store.getters.getToken,
    }
    if (authType) {
        headers.authType = authType
    }
    console.log('headers', headers)
    return new Promise((resolve, reject) => {
        instance.get(config.baseURL + url, {
            headers: {
                ...headers
            },
            params
        })
                .then(res => {
                    let {code, msg} = res.data
                    resolve(res.data)
                    if (code == 514) {
                        console.log(2222)
                        store.commit('CLEAR_TOKEN')
                        // router.back()
                        // Message.error(msg)
                    }
                })
                .catch(err => {
                    Message({
                        type: 'error',
                        message: '数据请求出错！',
                        duration: 5 * 1000
                    })
                    reject(err.data)
                })
    })
}

export function post(url, data) {
    let authType = store.state.user.authType
    let headers = {
        ...config.headers,
        token: store.getters.getToken,
    }
    if (authType) {
        headers.authType = authType
    }
    return new Promise((resolve, reject) => {
        instance.post(config.baseURL + url, data, {headers})
                .then(res => {
                    let {code, msg} = res.data
                    resolve(res.data)
                    if (code == 514) {
                        console.log(2222)
                        store.commit('CLEAR_TOKEN')
                        // router.back()
                        Message.error(msg)
                    }
                })
                .catch(err => {
                    Message({
                        type: 'error',
                        message: '数据请求出错！',
                        duration: 5 * 1000
                    })
                    reject(err.data)
                })
    })
}

export function postText(url, data) {
    return new Promise((resolve, reject) => {
        instance.post(config.baseURL + url, data, {
            headers: {
                'Cache-Control': 'no-cache',
                token: store.getters.getToken,
            }
        })
                .then(res => {
                    let {code, msg} = res.data
                    resolve(res.data)
                    if (code == 514) {
                        console.log(2222)
                        store.commit('CLEAR_TOKEN')
                        // router.back()
                        Message.error(msg)
                    }
                })
                .catch(err => {
                    Message({
                        type: 'error',
                        message: '数据请求出错！',
                        duration: 5 * 1000
                    })
                    reject(err.data)
                })
    })
}

export function allUrlPost(url, data, configHeader) {
    let headers = ''
    if (configHeader) {
        headers = {
            ...configHeader,
            ...config.headers,
            token: store.getters.getToken,
        }
    } else {
        headers = {
            ...configHeader,
            ...config.headers,
            token: store.getters.getToken,
        }
    }

    return new Promise((resolve, reject) => {
        instance.post(url, data, {
            headers
        })
                .then(res => {
                    let {code, msg} = res.data
                    resolve(res.data)
                    if (code == 514) {
                        console.log(2222)
                        store.commit('CLEAR_TOKEN')
                        // router.back()
                        Message.error(msg)
                    }
                })
                .catch(err => {
                    Message({
                        type: 'error',
                        message: '数据请求出错！',
                        duration: 5 * 1000
                    })
                    reject(err.data)
                })
    })
}

export function allUrlGet(url, data, configHeader) {
    let headers = ''
    if (configHeader) {
        headers = {
            ...configHeader,
            ...config.headers,
            token: store.getters.getToken,
        }
    } else {
        headers = {
            ...configHeader,
            ...config.headers,
            token: store.getters.getToken,
        }
    }

    return new Promise((resolve, reject) => {
        instance.get(url, {
            headers,
            params: data
        })
                .then(res => {
                    let {code, msg} = res.data
                    resolve(res.data)
                    if (code == 514) {
                        console.log(2222)
                        store.commit('CLEAR_TOKEN')
                        // router.back()
                        Message.error(msg)
                    }
                })
                .catch(err => {
                    Message({
                        type: 'error',
                        message: '数据请求出错！',
                        duration: 5 * 1000
                    })
                    reject(err.data)
                })
    })
}

export default {
    get,
    post,
    allUrlPost,
    allUrlGet
}



