

const answers = [
    {
        path: '/answers1',
        name: 'AnswersTemp-1',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../../views/answer/AnswerTemp-1/Index')
    },

    {
        path: '/answers1-details',
        name: 'AnswersTemp-1-details',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../../views/answer/AnswerTemp-1/Details')
    },
    {
        path: '/answers1-rank',
        name: 'AnswersTemp-1-rank',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../../views/answer/AnswerTemp-1/Rank')
    },
    {
        path: '/answers1-result',
        name: 'AnswersTemp-1-result',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../../views/answer/AnswerTemp-1/Result')
    },
    {
        path: '/answers1-form',
        name: 'AnswersTemp-1-form',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../../views/answer/AnswerTemp-1/Form')
    },
]

export default answers
