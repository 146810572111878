const getters = {
    getTheme: state => state.theme.themeClass,
    getToken: state => state.user.token,
    getOpenId: state => state.user.openId,
    getLoginDialog: state => state.user.loginDialog,
    getAuthType: state => state.user.authType,
    getBindInfo: state => {
        return {
            openId: state.user.openId,
            accessToken: state.user.accessToken
        }
    },
    getFlutter: state => state.user.isFlutter,
}
export default getters
