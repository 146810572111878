import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import votes from './moudles/vote'
import signs from './moudles/sign'
import questionnaires from './moudles/questionnaire'
import answers from './moudles/answer'
import draws from './moudles/draw'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/withdraw',
        name: 'withdraw',
        component: () => import(/* webpackChunkName: "about" */ '../views/with-draw/WithDraw')
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import(/* webpackChunkName: "about" */ '../views/logout/Logout')
    },
    ...votes,
    ...signs,
    ...questionnaires,
    ...answers,
    ...draws
]

const router = new VueRouter({
    routes,
    scrollBehavior: () => ({y: 0}),
})

export default router
