

const questionnaires = [
    {
        path: '/questionnaire1',
        name: 'QuestionnaireTemp-1',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../../views/questionnaire/QuestionnaireTemp-1/Index')
    },
]

export default questionnaires
