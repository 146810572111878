// 设置 rem 函数
function setRem () {

    // 320 默认大小16px; 320px = 20rem ;每个元素px基础上/16
    let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
//得到html的Dom元素
    let htmlDom = document.getElementsByTagName('html')[0];
//设置根元素字体大小
    htmlDom.style.fontSize= htmlWidth/20 + 'px';
}

let ua = navigator.userAgent
let ipad = ua.match(/(iPad).*OS\s([\d_]+)/)
let isIphone =!ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/)
let isAndroid = ua.match(/(Android)\s+([\d.]+)/)
isMobile = isIphone || isAndroid

if(isMobile){
    setRem();
}else{

}
// 初始化

// 改变窗口大小时重新设置 rem
window.onresize = function () {
    if(isMobile){
        setRem();
    }else{

    }
    // setRem()
}