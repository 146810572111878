import http, {allUrlPost, allUrlGet} from '../js/http'

// yd登录
export function ydLogin(params, url) {
    return http.get(url, params)
    // return http.get('/queryAllChannelBaseInfoList.do?timestamp=1639014758319&tokenID=4D14AD0732C492925D4765ABD20A33D5')
}

// 获得实名
export function getRealName() {
    return http.get('/activity/getRealName')
    // return http.get('/queryAllChannelBaseInfoList.do?timestamp=1639014758319&tokenID=4D14AD0732C492925D4765ABD20A33D5')
}

// 绑定实名
export function bindingRealName(params) {
    return http.get('/activity/bindingRealName', params)
}

// 检查用户是否已经完成了附加表单填写
export function getAttachFormFillStatus(params) {
    return http.get('/activity/getAttachFormFillStatus', params)
}

// 获得活动对应的附加表单
export function getAttachFormQuests(params) {
    return http.get('/activity/getAttachFormQuests', params)
}

//保存活动附加表单
export function saveAttachFormFill(params) {
    return http.post('/activity/saveAttachFormFill', params)
}

//查询钱包余额
export function searchMoney() {
    return http.allUrlPost('https://gateway.qiluyidian.net/gateway/pay/api/v1/user/wallet')
}

//提现
export function withdraw(data) {
    return http.allUrlPost('https://gateway.qiluyidian.net/gateway/pay/api/v1/user/with/draw', data, {
        source: '2'
    })
}

//提现状态
export function withdrawStatus(data) {
    return http.allUrlPost('https://gateway.qiluyidian.net/gateway/pay/api/v1/pay/business/info', data)
}

//登出
export function qlydlogout(data) {
    return http.allUrlPost('https://gateway.qiluyidian.net/gateway/auth/qlydlogout', data, {
        Authorization: 'Basic cWx5ZC1hcHA6cWx5ZC1hcHA='
    })
}

// 统计
export function exampleViews(data) {
    return http.allUrlPost(`https://gateway.qiluyidian.net/gateway/editor/manage/materillib/v1/exampleViews`, data)
}
