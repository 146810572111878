<template>
    <div id="app">
        <router-view ref="route"/>
    </div>
</template>

<script>
    import {exampleViews} from "./assets/api/user"

    export default {
        name: 'app',
        beforeCreate() {
            window.addEventListener("flutterInAppWebViewPlatformReady", (event) => {
                this.$store.commit('CHANGE_FLUTTER', true)
                console.log(this.$store.state.user.isFlutter)
            })
            this.$router.onReady(() => {
                console.log(this.$route.query.id)
                let id = this.$route.query.id
                if (id) {
                    exampleViews({id})
                }
            })
        }
        // computed: {
        //     theme(){
        //         return this.$store.getters.getTheme
        //     }
        // }
    }

</script>
<style lang="less">
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

</style>
