

const votes = [
    {
        path: '/vote1',
        // path: '/VoteTemp-1',
        name: 'VoteTemp-1',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../../views/vote/VoteTemp-1/Index')
    },
    {
        path: '/vote1-details',
        name: 'VoteTemp-1-details',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../../views/vote/VoteTemp-1/Details')
    }
]

export default votes
